
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import HRMService from "@/services/hrm-service";
import PopUp from "@/components/PopUp.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "@/services/filter-service";
import utils from "@/utils/utils";

@Component({
  components: { Header, Loader, PopUp, FilterComponent },
})
export default class UserManagement extends Vue {
  public currentPage = 1;
  loading = true;
  public permitUser: any = [];
  public selected_employees: any = [];
  public selected_employee: any = [];
  public userCreation: any = {};
  saveData = true;
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  getEmployees() {
    this.loading = true;
    HRMService.getAll()
      .then((res) => {
        this.selected_employee = res.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  retrieve() {
    this.loading = true;
    UserManagementService.getAll_user()
      .then((res) => {
        this.permitUser = res.data.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  get rows() {
    return this.permitUser.length;
  }
  paginatedItems() {
    const start = (this.currentPage - 1) * 20;
    const end = start + 20;
    return this.permitUser.slice(start, end);
  }
  mounted() {
    this.retrieve();
    this.getEmployees();
  }
  saveUser() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.userCreation, ['employee_title', 'first_name', 'last_name', 'email'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    }
    else {
      const selected_title: any = this.userCreation.employee_title;
      const emp_id = this.selected_employee
        .filter((employee: any) => employee.title === selected_title)
        .map((employee: any) => employee.id)[0];
      const userCreation: any = {
        employee_id: emp_id,
        first_name: this.userCreation.first_name,
        last_name: this.userCreation.last_name,
        email: this.userCreation.email
      }
      this.loading = true;
      UserManagementService.create_User(userCreation)
        .then((res) => {
          this.resetUser();
          this.retrieve();
          this.refreshFilters();
          this.showModal("Created Successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  editUserCreationToggle(data: any) {
    this.saveData = false;
    this.userCreation = data;
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-user-creation");
  }
  editUser() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.userCreation, ['employee_title', 'first_name', 'last_name', 'email'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    }
    else {
      this.loading = true;
      UserManagementService.update_User(this.userCreation.id, this.userCreation)
        .then((res) => {
          this.resetUser();
          this.retrieve();
          this.refreshFilters();
          this.showModal("Updated Successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  deleteUser(item: any) {
    this.loading = true;
    UserManagementService.deleteUser(item.id)
      .then((res) => {
        this.retrieve();
        this.refreshFilters();
        this.showModal("Deleted Successfully", [], "success");
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
  resetUser() {
    this.error = "";
    this.saveData = true;
    this.userCreation = {
      first_name: '',
      last_name: '',
      username: '',
      email: ''
    }
    for (let field of this.filter_fields) {
      field.value = "";
    }
  }
  index = 0;
  search_employee(title: any, index: any) {
    this.index = index;
    if (title.length > 2) {
      this.loading = true;
      UserManagementService.get_Employee_by_Title(title)
        .then((res) => {
          this.selected_employees = res.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    }
    if (title.length == 0) {
      this.selected_employees = [];
    }
  }
  get_data(data: any) {
    this.userCreation.employee_title = data.title;
    this.userCreation.first_name = data.first_name;
    this.userCreation.last_name = data.last_name;
    this.selected_employees = [];
  }
  filter_fields = [
    { type: "text", label: "User Name", name: "user-name", value: "", param_name: "username", filter_type: "simple", },
    // { type: "text", label: "Employee Title", name: "employee-title", value: "", param_name: "", filter_type: "simple", },
    { type: "text", label: "First Name", name: "first-name", value: "", param_name: "first_name", filter_type: "simple", },
    { type: "text", label: "Last name", name: "last-name", value: "", param_name: "last_name", filter_type: "simple", },
    { type: "text", label: "Email", name: "email", value: "", param_name: "email", filter_type: "simple", },
  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.get_users(simpleFilteredObj).then((data) => {
        if (
          data.data.data !== undefined &&
          data.data.data !== null
        ) {
          this.permitUser = data.data.data;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    }
  }
  refreshFilters() {
    this.resetUser();
  }
}
